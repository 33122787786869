import React, { useState, useCallback, useRef } from "react";
import styled from "styled-components";
import ditherBackground from "../../styles/ditherBackground";

const StyledIcon = styled.div`
  height: 42px;
  width: 42px;
  background-image: url(${(props) => props.background});
  background-size: 42px auto;
  cursor: pointer;

  &::after {
    content: undefined;
    display: block;
    width: 36px;
    height: 36px;
    mask-image: ${(props) => `url(${props.background})`};
    mask-size: 36px 36px;
    ${() => ditherBackground("rgb(0, 0, 127)")};
  }
`;

const StyledIconWithLabel = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  user-select: none;
  padding: 5px;
  box-sizing: border-box;

  ${(props) =>
    (props.clicked || props.dragging) &&
    `
      ${StyledIcon}::after {
        content: "";
      }
  `}

  span {
    color: rgb(255, 255, 255);
    font-size: 11px;
    padding: 1px 2px;
    margin-top: 7px;
    border: 1px solid transparent;
    text-align: center;
    max-width: 100%;
    overflow-wrap: break-word;
  }

  ${(props) =>
    (props.clicked || props.dragging) &&
    `
      span {
        background-color: ${props.dragging ? 'rgba(0, 0, 255, 0.3)' : 'rgb(0, 0, 127)'};
        border: 1px dotted rgba(200, 200, 200);
      }
  `}
`;

function Icon({ handleClick, label, img, clicked, initialTop, initialLeft, onDragEnd }) {
  const [position, setPosition] = useState({ top: initialTop, left: initialLeft });
  const [isDragging, setIsDragging] = useState(false);
  const dragStartRef = useRef({ x: 0, y: 0, time: 0 });
  const iconRef = useRef(null);

  const handleMouseDown = useCallback((e) => {
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      dragStartRef.current = { x: e.clientX, y: e.clientY, time: Date.now() };
      setIsDragging(true);

      const handleMouseMove = (e) => {
        const newLeft = `${e.clientX - offsetX}px`;
        const newTop = `${e.clientY - offsetY}px`;
        setPosition({ top: newTop, left: newLeft });
      };

      const handleMouseUp = (e) => {
        setIsDragging(false);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        onDragEnd(label, position.top, position.left);

        const dragDistance = Math.sqrt(
          Math.pow(e.clientX - dragStartRef.current.x, 2) +
          Math.pow(e.clientY - dragStartRef.current.y, 2)
        );
        const dragTime = Date.now() - dragStartRef.current.time;

        // If drag distance is less than 5px and drag time is less than 200ms, consider it a click
        if (dragDistance < 5 && dragTime < 200) {
          handleClick(label);
        }
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
  }, [label, onDragEnd, position.top, position.left, handleClick]);

  return (
    <StyledIconWithLabel
      ref={iconRef}
      onMouseDown={handleMouseDown}
      clicked={clicked}
      dragging={isDragging}
      top={position.top}
      left={position.left}
    >
      <StyledIcon background={img} />
      <span>{label}</span>
    </StyledIconWithLabel>
  );
}

export default Icon;
